.service-container{
    background-color: #000520;
}
.service-container:hover{
    background-image: linear-gradient(to right, #000520, #003b55, #007283, #44aca2, #9ae6b4);
    transition-timing-function: ease;
    box-shadow: 10px 5px 5px #000520;
}
.heading-fonts{
    font-family: "Light";
}