@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Nova+Flat');
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&family=Open+Sans:ital,wght@0,500;1,400&family=Playball&display=swap');


body {
  background-color: #FFFFFF;
caret-color: transparent;
}
.Sub-Image{
  height: 100%;
    width: 100%;
    box-shadow: 10px 5px 5px #000520;
}
.card-Image{
  object-fit: cover;
  height: 100%;
    width: 100%;
    position: absolute;
}
@font-face {
  font-family: "Light";
  src: local("GT-Super-Display-Bold"),
  url("./fonts/GT-Super-Display-Bold.ttf")
}
.heading-fonts{
  font-family: "Light";
}
.choose-us-icons{
  height:8vh;
  width:'60px'
}
.about-image{
  box-shadow: 10px 5px 5px #000520;
}